import { RewardDiscountType, FixedRewardSource } from './constants';
import { FlexibleRewardConfig } from './getFlexibleRewardConfig';
import { FixedReward, LoyaltyFixedReward } from './getValidFixedRewards';

interface BaseNextRewardDetails {
  discountType: RewardDiscountType;
  costInPoints: number;
  missingUserPoints: number;
}

interface MoneyNextRewardDetails extends BaseNextRewardDetails {
  discountType: RewardDiscountType.Money;
  moneyAmountDiscount: number;
}

interface PercentageNextRewardDetails extends BaseNextRewardDetails {
  discountType: RewardDiscountType.Percentage;
  percentageDiscount: number;
}

interface FreeShippingNextRewardDetails extends BaseNextRewardDetails {
  discountType: RewardDiscountType.FreeShipping;
}

export type NextRewardDetails = MoneyNextRewardDetails | PercentageNextRewardDetails | FreeShippingNextRewardDetails;

export function calculateNextRewardDetails(
  nextFlexibleRewardDetails: NextRewardDetails | undefined,
  nextFixedRewardDetails: NextRewardDetails | undefined,
): NextRewardDetails | undefined {
  const nextRewardDetailsList = [nextFlexibleRewardDetails, nextFixedRewardDetails].filter(
    (rewardDetails) => !!rewardDetails,
  ) as NextRewardDetails[];

  return nextRewardDetailsList.sort((a, b) => a.missingUserPoints - b.missingUserPoints)[0];
}

export function calculateNextFlexibleRewardDetails(
  flexibleRewardConfig: FlexibleRewardConfig,
  userPointsAmount: number,
): NextRewardDetails | undefined {
  const { costInPoints, discount } = flexibleRewardConfig;
  const missingUserPoints = Math.max(costInPoints - userPointsAmount, 0);

  if (missingUserPoints > 0) {
    return {
      discountType: RewardDiscountType.Money,
      costInPoints,
      missingUserPoints,
      moneyAmountDiscount: discount,
    };
  }
}

export function calculateNextFixedRewardDetails(
  validFixedRewards: FixedReward[],
  userPointsAmount: number,
): NextRewardDetails | undefined {
  const nextLoyaltyFixedReward = validFixedRewards
    .filter<LoyaltyFixedReward>((fixedReward): fixedReward is LoyaltyFixedReward =>
      fixedReward.source === FixedRewardSource.LoyaltyReward ? fixedReward.costInPoints > userPointsAmount : false,
    )
    .sort((a, b) => a.costInPoints! - b.costInPoints!)[0];

  if (nextLoyaltyFixedReward) {
    const { discountType, costInPoints } = nextLoyaltyFixedReward;
    const missingUserPoints = Math.max(costInPoints - userPointsAmount, 0);

    const commonNextRewardDetails = {
      costInPoints,
      missingUserPoints,
    };

    if (discountType === RewardDiscountType.Money) {
      return {
        ...commonNextRewardDetails,
        discountType,
        moneyAmountDiscount: nextLoyaltyFixedReward.moneyAmountDiscount,
      };
    } else if (discountType === RewardDiscountType.Percentage) {
      return {
        ...commonNextRewardDetails,
        discountType,
        percentageDiscount: nextLoyaltyFixedReward.percentageDiscount,
      };
    } else if (discountType === RewardDiscountType.FreeShipping) {
      return {
        ...commonNextRewardDetails,
        discountType,
      };
    }
  }
}
