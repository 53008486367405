import model from './model';
import { ElementId } from './constants';

export default model.createController(({ $bind, $props, $widget, $w }) => {
  return {
    async pageReady() {
      const pointsInputSelector = `#${ElementId.PointsInput}`;
      const pointsInput = $w(pointsInputSelector);

      pointsInput.onCustomValidation((value, reject) => {
        const errorMessage = $props.errorMessage;
        if (errorMessage && !$props.hasFailedToApplyReward) {
          reject(errorMessage);
        }
      }, true);

      $bind(`#${ElementId.PointsInputLabelText}`, {
        collapsed: () => !$props.inputLabel,
        text: () => $props.inputLabel,
      });

      $bind(pointsInputSelector, {
        placeholder: () => $props.inputPlaceholder,
        value: () => $props.inputValue,
        onInput(event) {
          $widget.fireEvent('input', event.target.value);
        },
        onBlur() {
          $widget.fireEvent('inputBlur', {});
        },
        onChange() {
          $widget.fireEvent('inputChange', {});
        },
      });

      $bind(`#${ElementId.Error}`, {
        collapsed: () => !$props.errorMessage,
      });

      $bind(`#${ElementId.ErrorText}`, {
        text: () => $props.errorMessage,
      });

      $bind(`#${ElementId.StatusText}`, {
        collapsed: () => !$props.statusText,
        text: () => $props.statusText,
      });
    },
    exports: {},
  };
});
