import model from './model';
import { ElementId } from './constants';

export default model.createController(({ $bind, $props }) => {
  return {
    async pageReady() {
      $bind(`#${ElementId.LabelText}`, {
        collapsed: () => !$props.label,
        text: () => $props.label,
      });

      $bind(`#${ElementId.ProgressBar}`, {
        targetValue: () => $props.progressMaxValue,
        value: () => $props.progressValue,
      });

      $bind(`#${ElementId.ProgressStatusText}`, {
        collapsed: () => !$props.progressStatus,
        text: () => $props.progressStatus,
      });
    },
    exports: {},
  };
});
