import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { applyDiscountToCheckout } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/http';

import { FixedRewardSource } from './constants';
import { FixedReward } from './getValidFixedRewards';

interface ApplyFlexibleRewardParams {
  flowAPI: PlatformControllerFlowAPI;
  checkoutId: string;
  fixedReward: FixedReward;
}

export async function applyFixedReward({ flowAPI, checkoutId, fixedReward }: ApplyFlexibleRewardParams): Promise<void> {
  const { httpClient } = flowAPI;
  const { id, revision, source } = fixedReward;

  await httpClient.request(
    applyDiscountToCheckout({
      checkoutId,
      ...(source === FixedRewardSource.LoyaltyReward && { reward: { id, revision } }),
      ...(source === FixedRewardSource.LoyaltyCoupon && { loyaltyCoupon: { id, revision } }),
      ...(source === FixedRewardSource.ReferralReward && { referralReward: { id, revision } }),
    }),
  );
}
